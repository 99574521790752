import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";

import { DatumModule } from "src/app/shared/directives/datum";
import { MapPipeModule } from "src/app/shared/pipes/map";
import { SharedModule } from "src/app/shared/shared.module";
import { OrdinalPipeModule } from "src/app/shared/pipes/ordinal";
import { OptionModule } from "src/app/shared/directives/option";

import { CartModalComponent } from "./cart.modal";

import { CartDetailsDisplayComponent } from "./cart-details/cart-details.display.component";
import { CartPromoCodeComponent } from "./promo-code/promo-code.component";
import { CartTermsOfServiceComponent } from "./terms-of-service/terms-of-service.component";
import { ModalRouteModule } from "src/app/shared/components/routed-modal";
import { CartVehiclesComponent } from "./cart-vehicles/cart-vehicles.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { CartPaymentComponent } from "./cart-payment/cart-payment.component";
import { CartPromoCodeAppliedComponent } from "./promo-code-applied/promo-code-applied.component";
import { CartTotalsComponent } from "./cart-totals/cart-totals.component";
import { CartMembershipEditComponent } from "./cart-membership-edit/cart-membership-edit.component";
import { CartPlansDifferenceComponent } from "./cart-membership-edit/plans-difference.component";
import { CartHoldsComponent } from "./cart-holds/cart-holds.component";
import { CartItemsComponent } from "./cart-items/cart-items.component";
import { CartVehicleComponent } from "./cart-vehicles/cart-vehicle.component";
import { CartPlanDetailComponent } from "./cart-plan-detail/cart-plan-detail.component";
import { CartNewMembershipComponent } from "./cart-new-membership/cart-new-membership.component";
import { MyqqButtonModule } from "src/app/shared/components/button/button.module";
import { NgxMaskDirective, provideNgxMask } from "ngx-mask";
import { CartVehicleEmptyComponent } from "./cart-vehicles/cart-vehicle-empty.component";
import { LetModule } from "@ngrx/component";

@NgModule({
  imports: [
    CommonModule,
    DatumModule,
    FormsModule,
    MapPipeModule,
    MatCardModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MyqqButtonModule,
    ModalRouteModule,
    OptionModule,
    OrdinalPipeModule,
    ReactiveFormsModule,
    SharedModule,
    NgxMaskDirective,
    LetModule,
  ],
  exports: [],
  declarations: [
    CartDetailsDisplayComponent,
    CartHoldsComponent,
    CartItemsComponent,
    CartMembershipEditComponent,
    CartModalComponent,
    CartPaymentComponent,
    CartPlansDifferenceComponent,
    CartPromoCodeAppliedComponent,
    CartPromoCodeComponent,
    CartTermsOfServiceComponent,
    CartTotalsComponent,
    CartVehicleComponent,
    CartVehiclesComponent,
    CartPlanDetailComponent,
    CartNewMembershipComponent,
    CartPlanDetailComponent,
    CartVehicleEmptyComponent,
  ],
  providers: [provideNgxMask()],
})
export class CartModule {}
