// THIS FILE IS AUTOGENERATED

export interface Version {
  commit: string;
  version: string;
  app: string;
}

export const version = {
  app: "myqq",
  commit: "aab55fd7a4a064c6d83a50cc74cc3dd8c07277be",
  version: "1.100.0",
};
