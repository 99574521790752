<ng-container *ngIf="order?.orderType || calculateOrderError">
  <div class="fld-col fld-dsk-row flg-5" [useOption]="membershipPurchaseOption">
    <span class="fld-col flg-5">
      <myqq-cart-membership-edit
        *ngIf="modifySubscriptionPlan | map: isModifySubscriptionOrder"
        [targetSubscriptionPlan]="modifySubscriptionPlan"
        [currentSubscriptionPlan]="currentSubscriptionPlan"
      >
      </myqq-cart-membership-edit>

      <myqq-cart-plan-detail
        *onSome="let membershipPurchase"
        [plan]="membershipPurchase.wash"
        [price]="membershipPurchase.wash?.base?.price"
        [priceOverride]="
          membershipPurchase.wash | map: getpriceOverride:promoDetails
        "
        [customOverride]="
          membershipPurchase.wash | map: getCustomPlanOverrideImg:promoDetails
        "
        [crossOutDefaultPrice]="promoDetails?.crossOutDefaultPrice"
      >
      </myqq-cart-plan-detail>
    </span>
    <section class="fld-col fls-1-1 flg-5">
      <myqq-plans-difference
        *ngIf="modifySubscriptionPlan | map: isModifySubscriptionOrder"
        class="ds-dsk-only"
        [targetSubscriptionPlan]="modifySubscriptionPlan"
        [currentSubscriptionPlan]="currentSubscriptionPlan"
      ></myqq-plans-difference>
      <span
        *ngIf="modifySubscriptionPlan | map: isModifySubscriptionOrder"
        class="divider"
      ></span>
      <myqq-cart-holds *ngIf="order?.holds?.length > 1" [holds]="order.holds">
      </myqq-cart-holds>

      <myqq-cart-items
        *ngIf="
          !!order && modifySubscriptionPlan | map: isModifySubscriptionOrder;
          else cartVehicles
        "
        [items]="order?.items"
      >
      </myqq-cart-items>
      <myqq-cart-applied-promo-code
        *ngIf="order?.markDowns?.length > 0"
        [markDowns]="order.markDowns"
        [totalMarkDowns]="order.totalMarkDowns"
        (clearPromoCode)="clearPromoCode.emit()"
      >
      </myqq-cart-applied-promo-code>
      <myqq-cart-totals
        [orderTotal]="order?.orderTotal"
        [currentSubscription]="currentSubscription"
        [nextBillingAmount]="order?.nextBillingAmount"
        [promoMessage]="promoDetails?.message"
      >
      </myqq-cart-totals>

      <span class="divider"></span>

      <myqq-cart-payment
        [paymentMethods]="paymentMethods"
        [orderType]="order?.orderType"
        [isMobile]="isMobile | async"
        (createPaymentMethod)="createPaymentMethod.emit()"
      >
      </myqq-cart-payment>

      <myqq-cart-promo-code
        *ngIf="!!order && order | map: isValidForPromo"
        [markDowns]="order?.markDowns || []"
        (tryPromo)="tryPromoCode.emit($event)"
        (clearPromoCode)="clearPromoCode.emit()"
        class="fls-0-0"
      >
      </myqq-cart-promo-code>
      <div *ngIf="calculateOrderError === invalidCouponErr" id="invalid-promo">
        Invalid promo for order. Recalculating...
      </div>
      <myqq-cart-terms-of-service></myqq-cart-terms-of-service>
    </section>
  </div>
</ng-container>
<ng-template #cartVehicles>
  <div *ngIf="!order?.orderType" class="fld-row jc-ctr spinner">
    <myqq-small-loader [show]="true" status="Loading"></myqq-small-loader>
  </div>
  <myqq-cart-vehicles
    #vehicleList
    [orderItems]="order?.items"
    [orderType]="order?.orderType"
    [vehicles]="vehicles"
    (createVehicle)="createVehicle.emit(vehicleList.selectedVehicles)"
    (vehiclesInCartDidUpdate)="recalculateOrderWithVehicles.emit($event)"
  >
  </myqq-cart-vehicles>
</ng-template>
