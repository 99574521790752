import { groupBy as _groupBy } from "lodash-es";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  OrderItem,
  OrderTypesEnum,
  PostCalculateOrderResponse,
  Vehicle,
} from "src/app/core/services/myqq";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { getMakeModelFromMakeIdModel } from "src/lib/util";
export interface VehicleWithOrderItem extends Vehicle {
  orderItem: OrderItem;
}

@Component({
  selector: "myqq-cart-vehicle",
  templateUrl: "./cart-vehicle.component.html",
  styleUrls: ["./cart-vehicle.component.scss"],
})
export class CartVehicleComponent {
  @Input() vehicle?: Vehicle;
  @Input() disabled = false;
  @Input() orderType?: OrderTypesEnum;
  @Input() hideToggle = false;
  @Input("orderItems") set _orderItems(
    items: Record<string, PostCalculateOrderResponse["items"]>
  ) {
    const currentVehicle = items[this.vehicle?.vehicleId];
    this.currentPrice = !!currentVehicle
      ? currentVehicle[0].grossTotal.toString() || "0.0"
      : "0.0";
    this.selected = !!currentVehicle;
    this.isLoading = false;
  }

  @Output() vehicleSelected = new EventEmitter<boolean>();

  isLoading: boolean = false;
  currentPrice: string = "0.0";
  status: string = "LOADING";
  selected: boolean = false;

  readonly OrderTypes = OrderTypesEnum;

  readonly toMakeModel = ({ year, make, model, color }: Vehicle) =>
    [year, color, ...getMakeModelFromMakeIdModel(make, model)]
      .filter(Boolean)
      .join(" ");

  handleVehicleSelectionChanged({ checked }: MatSlideToggleChange) {
    this.isLoading = true;
    this.status = checked ? "ADDING" : "REMOVING";
    this.selected = checked;
    this.vehicleSelected.emit(checked);
  }
}
